<template>
  <main>
    <OrderStep :step="4"/>
    <div class="container">
      <Error :mainText="confirmationMainText"
                    :subText="confirmationSubText" />
    </div>
  </main>
</template>

<script>
import OrderStep from "@/components/OrderStep";
import Error from "@/components/Error";

export default {
  name: "PaymentError",
  components:{Error, OrderStep},
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
    response:{
      type: String,
      required: true,
    },
  },
  data () {
    return {
      confirmationMainText: `
			Erreur de Paiement,
			<br/> le paiement de votre commande ${this.orderId ? this.orderId : ''}<br/> à échoué !
		`,
      confirmationSubText: `
			Un e-mail de confirmation vous a été envoyé.
			<br/>Veuillez nous contacter au
			<br/><a href="tel:0299894819" target="_blank" style="color: black">02 99 89 48 16</a>
			<br/> afin de finaliser votre commande.
		`,
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 0 auto;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 992px) {
    width: 90vw;
  }
  @media screen and (min-width: 1200px) {
    width: 80vw;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

</style>